<template>
  <div id="acctoken">123213</div>
</template>

<script>
import { getToken, setToken, Getguid, remove } from "../utils/auth";
export default {
  name: "acctoken",
  data() {
    return {};
  },

  created() {
    this.$store.dispatch("setLoading", true);
    var acctoken = this.$route.query.access_token;
    setToken(acctoken);
    setTimeout(() => {
      // this.$store.dispatch("setLoading", false);
      var data = Getguid();
      let a = document.createElement("a");
      a.setAttribute("href", data);
      document.body.appendChild(a);
      remove();
      a.click();
    }, 2000);
  },

  computed: {},

  methods: {},
};
</script>

<style scoped lang="less">
</style>

